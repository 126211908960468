export function getRandomInt(low, high)
{
    return Math.floor(Math.random() * (high - low + 1)) + low;
}

export function createRandomFriendlyMatrixWithSize(rows, cols)
{
    let matrix = [];
    // Do not use non-integer values in matrices, and keep the magnitudes
    // small.
    let r = 0;
    for (r = 0; r < rows; ++r) {
        let c = 0;
        matrix.push([]);
        for (c = 0; c < cols; ++c) {
            const value = getRandomInt(-15, 15);
            matrix[r].push(value);
        }
    }

    return matrix;
}

export function createRandomFriendlyMatrix()
{
    // Do not make the matrix too big.
    const rows = getRandomInt(2, 3);
    const cols = getRandomInt(2, 3);

    return createRandomFriendlyMatrixWithSize(rows, cols);
}

export function drawMatrixBrackets(board, position, scale, numRows, numCols, suspend)
{
    let i = 0;

    // A quick explanation of the various components and sizes:
    // position[0], position[1] is the center of the top left component
    // scale is the total width and total height of a component.

    const topLeft =
        [
            position[0] - scale / 2.0,
            position[1] + scale / 2.0
        ];

    const topLeftInner =
        [
            position[0] - scale / 2.0 + scale / 4.0,
            position[1] + scale / 2.0
        ];

    const topRight =
        [
            position[0] + scale * numCols - scale / 2.0,
            position[1] + scale / 2.0
        ];

    const topRightInner =
        [
            position[0] + scale * numCols - scale / 2.0 - scale / 4.0,
            position[1] + scale / 2.0
        ];

    const bottomRight =
        [
            position[0] + scale * numCols - scale / 2.0,
            position[1] - scale * numRows + scale / 2.0
        ];

    const bottomRightInner =
        [
            position[0] + scale * numCols - scale / 2.0 - scale / 4.0,
            position[1] - scale * numRows + scale / 2.0
        ];

    const bottomLeft =
        [
            position[0] - scale / 2.0,
            position[1] - scale * numRows + scale / 2.0
        ];

    const bottomLeftInner =
        [
            position[0] - scale / 2.0 + scale/4.0,
            position[1] - scale * numRows + scale / 2.0
        ];

    const horzLeft1 =
        [
            position[0]-0.5*scale,
            position[1] - scale * numRows + 1.5*scale
        ];

    const horzRight1 =
        [
            position[0] + scale*numCols -0.5*scale,
            position[1] - scale * numRows + 1.5*scale
        ];

    const horzLeft2 =
        [
            position[0]-0.5*scale,
            position[1] - scale * numRows + 2.5*scale
        ];

    const horzRight2 =
        [
            position[0] + scale*numCols -0.5*scale,
            position[1] - scale * numRows + 2.5*scale
        ];

    const vertTop1 =
        [
            position[0]+0.5*scale,
            position[1]+0.5*scale
        ];

    const vertBot1 =
        [
            position[0]+0.5*scale,
            position[1] - scale * numRows + 0.5*scale
        ];

    const topY    = position[1] + 0.5*scale;
    const bottomY = position[1] - scale * numRows + 0.5*scale

    const vert2 = [
        [
            position[0]+scale*numCols -1.5*scale,
            position[1] + 0.5*scale
        ],
        [
            position[0]+scale*numCols -1.5*scale,
            position[1] - scale * numRows + 0.5*scale
        ]
    ];

    let objectList = [];

    let trackedSegment = function(points, attributes) {
        let jsxPoints = [];
        for (const point of points) {
            let jsxPoint = board.create('point', point, {
                withLabel: false,
                fixed: true,
                visible: false
            });
            jsxPoints.push(jsxPoint);
            objectList.push(jsxPoint);
        }
        objectList.push(board.create('segment', jsxPoints, attributes));
    }

    const bracketAttributes = {
        fixed: true,
        color: 'black',
        highlight: false
    };

    const separatorAttributes = {
        // visible: () => numRows > 1 && i == 1,
        strokeWidth: 1,
        dash: 1,
        fixed: true,
        color: 'black',
        highlight: false
    }

    if (suspend) {
        board.suspendUpdate();
    }

    trackedSegment([vertBot1, vertTop1], {...separatorAttributes, visible:()=>numCols>1&&i==1});
    trackedSegment(vert2, {...separatorAttributes, visible:()=>numCols>2&&i==1});

    trackedSegment([horzLeft1, horzRight1], {...separatorAttributes, visible:()=>numRows>1&&i==1});
    trackedSegment([horzLeft2, horzRight2], {...separatorAttributes, visible:()=>numRows>2&&i==1});

    // Left bracket for matrix elements '['.
    trackedSegment([bottomLeft, topLeft],           bracketAttributes);
    trackedSegment([bottomLeft, bottomLeftInner],   bracketAttributes);
    trackedSegment([topLeft, topLeftInner],         bracketAttributes);

    // Right bracket for matrix elements ']'.
    trackedSegment([bottomRight, topRight],         bracketAttributes);
    trackedSegment([bottomRight, bottomRightInner], bracketAttributes);
    trackedSegment([topRight, topRightInner],       bracketAttributes);

    if (suspend) {
        board.unsuspendUpdate();
    }

    return [objectList, i];
}

export function drawMatrix(board, position, scale, matrix, onElementSelect = null, onElementLeave = null, labelMatrixAssignment = false)
{
    const dx = scale;
    const dy = scale;
    const numRows = matrix.length;
    const numCols = matrix[0].length;

    board.suspendUpdate();
    let [objectList, i]  = drawMatrixBrackets(board, position, scale, numRows, numCols, false);

    if (labelMatrixAssignment) {
        objectList.push(board.create('text',
            [
                position[0] - scale,
                position[1] - scale * numRows / 2.0 + scale / 2.0,
                "M ="
            ],
            {
                anchorX: 'right', anchorY: 'middle', fixed: true,
                CssStyle: 'fontFamily: Oswald',
                display: 'internal',
                highlight:false,
                fontSize: () => Math.round(18*board.canvasWidth/500.),
            }
        ));
    }

    const matrixElementTextProperties = {
        anchorX: 'middle',
        anchorY: 'middle',
        CssStyle: 'fontFamily: Oswald',
        display: 'internal',
        color: 'grey',
        fontSize: () => Math.round(18*board.canvasWidth/500.),
        fixed: true,
        highlight: false,
    };

    let r = 0;
    let textLabels = []
    for (r = 0; r < numRows; ++r) {
        let c = 0;
        const rr = r;
        textLabels.push([]);
        for (c = 0; c < numCols; ++c) {
            const value = matrix[r][c];
            let text = board.create('text',
                            [position[0] + c * dx,
                             position[1] - r * dy,
                             String(value)],
                            matrixElementTextProperties,
                        );
            const cc = c;
            text.on('over', function () {
            //    this.setAttribute({ color: 'black' });
                if (onElementSelect) {
                    i = 1;
                    onElementSelect(matrix, rr, cc, text);
                }
            });
            text.on('out', function() {
                this.setAttribute({ color: 'grey' });
                if (onElementLeave) {
                    i = 0;
                    onElementLeave(matrix, rr, cc, text);
                }
            }); 
            objectList.push(text);
            textLabels[r].push(text);
        }
    }
    board.unsuspendUpdate();

    return [objectList, textLabels];
}

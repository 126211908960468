<template>
  <div>
    <h3 ref="mat">
      What is a Matrix?
    </h3>
    <p>
      A matrix is a collection or ensemble of numbers that obey specific algebraic rules for addition, subtraction and multiplication.
      A matrix is typically represented by means of rows and columns. For example: \(\mathbf{A}\), shown below, is a matrix
      containing 2 rows and 2 columns.
      $$
      \mathbf{A} = \begin{bmatrix}
      1 & 4 \\
      -1 & 1 \\
      \end{bmatrix}
      $$
      \(\mathbf{B}\) is a matrix containing 3 rows and 2 columns.
      $$
      \mathbf{B} = \begin{bmatrix}
      1 & 4 \\
      -1 & 1 \\
      3 & 8 \\
      \end{bmatrix}
      $$
    </p>
    <h3 ref="comps">
      Components of a Matrix
    </h3>
    <p>
      The individual items of a matrix are called its components or elements. Typically an element of matrix \(\mathbf{M}\) that is located
      at the \(i^\text{th}\) row and \(j^\text{th}\) column is represented by the symbol \(M_{ij}\).
    </p>
    <h3 ref="magicgraph">
      MagicGraph &vert; Components of a Matrix
    </h3>
    <p>
      This MagicGraph offers interactive practice problems for understanding matrix components.
    </p>
    <h5> To Get Started: </h5>
    <p>
      You are given a matrix \(\textbf{M}\).
      <ul>
        <li>Hover your mouse over the number entries within the brackets.</li>
        <li>Look at the pop-up labels to determine the address (row and column) of the entry within the matrix.</li>
      </ul>
    </p>
    <h5> To Explore: </h5>
    <p> Tap on the shuffle button to create different variations of the matrix \(\textbf{M}\) and practice. </p>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
    <h3 ref="magicgraph">
      MagicGraph &vert; Test Your Knowledge
    </h3>
    <p>
      This MagicGraph offers interactive practice problems to test your knowledge.
    </p>
    <h5> To Get Started: </h5>
    <p>
      You are given a matrix \(\textbf{M}\).
      Hover over the components of the matrix to see its location (i.e. its row and column) and indices.
    </p>
    <h5> To Explore: </h5>
    <p> Tap on the shuffle button to create different variations of the matrix \(\textbf{M}\) and practice. </p>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox2" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'Angles',
  created: function () {
      this.$store.commit('navigation/resetState');
    // Store mutations
    const title = 'Components of a Matrix';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    const newTopics = [
      // {title: 'Index Notation', img:'/assets/number-1.svg', action: () => this.goto('index-notation')},
      {title: 'What can be added?', img:'/assets/number-1.svg', action: () => this.goto('what-can-be-added')},
      {title: 'Addition Example', img:'/assets/number-2.svg', action: () => this.goto('addition-example')},
      {title: 'MagicGraph',img:'/assets/touch.svg', action: () => this.goto('magicgraph')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1a();
    Boxes.box1b();
  },
  metaInfo() {
  return{ title: 'Components of a Matrix',
          titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively how to draw a free body diagram'}
                ]
        }
   },
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';

/*
   Center align the text in input fields.
*/
.JXGtext input {
    text-align: center;
}
</style>

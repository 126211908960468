import {
    makeResponsive,
    placeTitle,
    clearInputFields,
} from '../../../../common/edliy_utils';
import {
    getRandomInt,
    createRandomFriendlyMatrix,
    drawMatrix,
} from '../matrix_utils';

const debugMode = true;
const giveHints = true;

const Boxes = {
////////////////////////////////////////////////////////////////////////////////
// Explanation
////////////////////////////////////////////////////////////////////////////////
    box1a: function () {
        const magicGraphProperties = {
            boundingbox: [-8, 12, 8, -4],
            keepaspectratio: true,
            axis: false,
            ticks: { visible: false },
            grid: true,
            showCopyright: false,
            showNavigation: false,
            pan: { enabled: false },
            zoom: { enabled: false },
        };

        let brd1 = JXG.JSXGraph.initBoard('jxgbox1', magicGraphProperties);
        brd1.suspendUpdate();
        makeResponsive(brd1);

        brd1.options.layer['image'] = 14;
        placeTitle(brd1, 'Components of a Matrix', '(Hover Over a Component to See its Indices)')

        let matrixComponentLabel = brd1.create('text', [0, 2.25, '(Component)'], {
            visible: false,
            anchorX: 'middle',
            CssStyle: 'fontFamily:Oswald',
            fontSize: () => Math.round(18 * brd1.canvasWidth / 500.),
            fixed: true,
        });

        const matrixComponentLabelProperties = {
            visible: false,
            fixed: true
        };

        let rows = [
            brd1.create('image', ['/assets/oneR.svg',   [-1.25, 7    - 0.5/2], [0.5, 0.5]], matrixComponentLabelProperties),
            brd1.create('image', ['/assets/twoR.svg',   [-1.25, 5.75 - 0.5/2], [0.5, 0.5]], matrixComponentLabelProperties),
            brd1.create('image', ['/assets/threeR.svg', [-1.25, 4.5  - 0.5/2], [0.5, 0.5]], matrixComponentLabelProperties),
        ];

        let cols = [
            brd1.create('image', ['/assets/oneG.svg',   [0 - 0.5 / 2,        7 + 1.25 / 2], [0.5, 0.5]], matrixComponentLabelProperties),
            brd1.create('image', ['/assets/twoG.svg',   [-3.75+5 - 0.5 / 2,  7 + 1.25 / 2], [0.5, 0.5]], matrixComponentLabelProperties),
            brd1.create('image', ['/assets/threeG.svg', [-2.50 +5 - 0.5 / 2, 7 + 1.25 / 2], [0.5, 0.5]], matrixComponentLabelProperties),
        ];

        let shuffle = brd1.create('image', ['/assets/shuffle.svg', [-0.75, 0.75 - 1.0], [1.5, 1.5]], {visible: true, fixed: true});

        const clearRowLabels = function() {
            for (let row of rows) {
                row.setAttribute({ visible: false });
            }
        };

        const clearColumnLabels = function() {
            for (let col of cols) {
                col.setAttribute({ visible: false });
            }
        }

        let matrix = createRandomFriendlyMatrix();
        const updateComponent = function (m, r, c) {
            clearRowLabels();
            clearColumnLabels();
            rows[r].setAttribute({ visible: true });
            cols[c].setAttribute({ visible: true });
            matrixComponentLabel.setText(`M_{<span style='color: red'>${String(r + 1)}</span><span style='color: green'>${String(c + 1)}</span>} = ${m[r][c]}`);
            matrixComponentLabel.setAttribute({ visible: true });
        }

        const clearComponent = function (m, r, c) {
            clearRowLabels();
            clearColumnLabels();
            matrixComponentLabel.setAttribute({ visible: false });
        }

        let [drawnObjects, ] = drawMatrix(brd1, [0, 7], 1.25, matrix,
                updateComponent,
                clearComponent,
                true);

        let newProblem = function() {
            brd1.suspendUpdate();
            if (drawnObjects) {
                brd1.removeObject(drawnObjects.reverse());
            }
            matrix = createRandomFriendlyMatrix();
            drawnObjects = drawMatrix(
                brd1, [0, 7], 1.25, matrix,
                updateComponent,
                clearComponent,
                true
            );
            brd1.unsuspendUpdate();
        }
        shuffle.on('down', newProblem);

        if (debugMode) {
            let debugMe = brd1.create('image', ['/assets/debug.svg', [-8, 11], [1, 1]], { visible: true, fixed: true });
            let dumpObjects = function() {
                console.log("Dumping jsxgraph objects.")
                let count = 0;
                for (const el in brd1.objects) {
                    console.log(brd1.objects[el]);
                    count++;
                }
                console.log(`Had ${count} object(s).`);
            }
            debugMe.on('down', dumpObjects);
        }

        brd1.unsuspendUpdate();
    },

////////////////////////////////////////////////////////////////////////////////
// Assessment
////////////////////////////////////////////////////////////////////////////////
    box1b: function () {
        const magicGraphProperties = {
            boundingbox: [-8, 12, 8, -4],
            keepaspectratio: true,
            axis: false,
            ticks: { visible: false },
            grid: true,
            showCopyright: false,
            showNavigation: false,
            pan: { enabled: false },
            zoom: { enabled: false },
        };

        let brd1 = JXG.JSXGraph.initBoard('jxgbox2', magicGraphProperties);
        brd1.suspendUpdate();
        makeResponsive(brd1);

        brd1.options.layer['image'] =14;
        // brd1.create('text', [-0, 10.5, 'Components of a Matrix'],{anchorX:'middle', display:'internal', CssStyle:'font-weight: bold; fontFamily: Oswald;',fontSize:() => Math.round(28*brd1.canvasWidth/500.), fixed:true});
        // brd1.create('text', [-0, 9.5, '(Hover Over a Component to See its Indices)'],{anchorX:'middle', display:'internal', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}, fixed:true});
        placeTitle(brd1, 'Components of a Matrix', '(Practice Problems)')

        let matrixComponentLabel = brd1.create('text', [0, 2.25, '(Component)'],
            {
                visible: false,
                anchorX:'middle',
                CssStyle: 'fontFamily:Oswald',
                fontSize:() => Math.round(18*brd1.canvasWidth/500.),
                fixed:true,
            });

        const matrixComponentLabelProperties = {
            visible: false,
            fixed: true
        };

        let rows = [
            brd1.create('image', ['/assets/oneR.svg',   [-1.25, 7    - 0.5/2], [0.5, 0.5]], matrixComponentLabelProperties),
            brd1.create('image', ['/assets/twoR.svg',   [-1.25, 5.75 - 0.5/2], [0.5, 0.5]], matrixComponentLabelProperties),
            brd1.create('image', ['/assets/threeR.svg', [-1.25, 4.5  - 0.5/2], [0.5, 0.5]], matrixComponentLabelProperties),
        ];

        let cols = [
            brd1.create('image', ['/assets/oneG.svg',   [-5.00 + 5 - 0.5 / 2, 7 + 1.25 / 2], [0.5, 0.5]], matrixComponentLabelProperties),
            brd1.create('image', ['/assets/twoG.svg',   [-3.75 + 5 - 0.5 / 2, 7 + 1.25 / 2], [0.5, 0.5]], matrixComponentLabelProperties),
            brd1.create('image', ['/assets/threeG.svg', [-2.50 + 5 - 0.5 / 2, 7 + 1.25 / 2], [0.5, 0.5]], matrixComponentLabelProperties),
        ];

        // let shuffle = brd1.create('image', ['/assets/shuffle.svg', [0,0], [1, 1]], {visible: true, fixed: true});
        let shuffle = brd1.create('image', ['/assets/shuffle.svg', [-0.75, 0.75 - 1.0], [1.5, 1.5]], {visible: true, fixed: true});

        let matrix = createRandomFriendlyMatrix();
        let numRows = matrix.length;
        let numCols = matrix[0].length;

        let [drawnObjects, ] = drawMatrix(brd1, [0, 7], 1.25, matrix, null, null, true);

        const assessmentScale = 1.5;
        const assessementIconSize = [assessmentScale, assessmentScale];
        let ok1 = brd1.create('image', ['/assets/check.svg',
                [
                    3.5 - assessmentScale / 2.0,
                    -2  - assessmentScale / 2.0
                ],
                [
                    assessmentScale,
                    assessmentScale
                ]
            ],
            { visible: false, fixed: true });
        let cross1 = brd1.create('image',
            ['/assets/cross.svg',
                [
                    3.5 - assessmentScale / 2.0,
                    -2  - assessmentScale / 2.0
                ],
                [
                    assessmentScale,
                    assessmentScale
                ]
        ],
            { visible: false, fixed: true });
        let tryMe = brd1.create('image',
            [
                '/assets/test.svg',
                [
                    6  - assessmentScale / 2.0,
                    -2 - assessmentScale / 2.0
                ],
                [
                    assessmentScale,
                    assessmentScale
                ]
            ],
            {
                visible: true,
                fixed: true
            }
        );

        let queryRow = getRandomInt(0, numRows - 1);
        let queryColumn = getRandomInt(0, numCols - 1);

        const promptOffset = [-0.5, 0];
        let prompt = brd1.create('text',
            [
                0 + promptOffset[0],
                -2 + promptOffset[1],
                () => `What is component M_{<span style='color: red'>${String(queryRow + 1)}</span><span style='color: green'>${String(queryColumn + 1)}</span>}? `
            ],
            {
                anchorX: 'right',
                CssStyle: 'fontFamily:Oswald',
                fontSize: () => Math.round(18 * brd1.canvasWidth / 500.),
                fixed: true,
            }
        );

        let component11 = brd1.create('input', [0, -2, '', ''],
            { fixed: true, cssStyle: 'fontFamily:Oswald; width:10%; background-color:#008CBA;border: 1px solid black;border-radius: 3.5px; margin: auto; text-align: center;', fontSize: function () { return Math.round(16 * brd1.canvasWidth / 500.) }, fixed: true, parse: true });

        let check = function() {
            if (component11.Value() == matrix[queryRow][queryColumn]) {
                ok1.setAttribute({ visible: true });
                cross1.setAttribute({ visible: false });

            }
            else {
                ok1.setAttribute({ visible: false });
                cross1.setAttribute({ visible: true });
            }
        }
        tryMe.on('down', check);

        let newProblem = function() {
            brd1.suspendUpdate();
            if (drawnObjects) {
                brd1.removeObject(drawnObjects.reverse());
            }
            matrix = createRandomFriendlyMatrix();
            numRows = matrix.length;
            numCols = matrix[0].length;
            queryRow = getRandomInt(0, numRows - 1);
            queryColumn = getRandomInt(0, numCols - 1);
            [drawnObjects, ] = drawMatrix(brd1, [0, 7], 1.25, matrix, null, null, true);
            cross1.setAttribute({ visible: false });
            ok1.setAttribute({ visible: false });
            clearInputFields(brd1);

            brd1.unsuspendUpdate();
        }
        shuffle.on('down', newProblem);

        if (debugMode) {
            let debugMe = brd1.create('image', ['/assets/debug.svg', [-8, 11], [1, 1]], { visible: true, fixed: true });
            let dumpObjects = function() {
                console.log("Dumping jsxgraph objects.")
                let count = 0;
                for (const el in brd1.objects) {
                    console.log(brd1.objects[el]);
                    count++;
                }
                console.log(`Had ${count} object(s).`);
            }
            debugMe.on('down', dumpObjects);
        }

        if (giveHints) {
            const scale = 0.75;
            let hintButton = brd1.create(
                'image',
                [
                    '/assets/light-bulb.svg',
                    [7.5 - (scale / 2.0), 11.5 - (scale / 2.0)],
                    [scale, scale]
                ],
                { visible: true, fixed: true }
            );
            let hideHint = function() {
                for (let c of cols) {
                    c.setAttribute({ visible: false });
                }
                for (let r of rows) {
                    r.setAttribute({ visible: false });
                }
            }
            let showHint = function() {
                for (let r = 0; r < numRows; ++r) {
                    rows[r].setAttribute({ visible: true });
                }
                for (let c = 0; c < numCols; ++c) {
                    cols[c].setAttribute({ visible: true });
                }
                setTimeout(hideHint, 2000);
            }
            hintButton.on('down', showHint);
        }

        brd1.unsuspendUpdate();
    },
};

export default Boxes;
